import store from "@/store";
import { defineComponent } from "vue";

export interface ParticipantFilter {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
}

const initFilter: ParticipantFilter = {
  id: "",
  name: "",
  startDate: "",
  endDate: "",
};

export const Filter = defineComponent({
  props: {
    onFilter: { type: Function, required: true },
    onDownload: { type: Function, required: true },
  },
  data() {
    return {
      filters: { ...initFilter },
      finishBeforeMount: false,
    };
  },
  beforeMount() {
    this.filters = { ...store.state.prevState.sponsorshipParticipant };
    this.finishBeforeMount = true;
  },
  beforeUnmount() {
    store.commit("setinsighPrevState", {
      sponsorshipParticipant: {
        ...this.filters,
      },
    });
  },
  render() {
    return (
      <section class="flex items-end my-8">
        <div class="mr-3 w-2/6">
          <pw-input type="text" label="Trip ID" placeholder="Enter Trip ID" class="mr-2" v-model={this.filters.id} />
        </div>
        <div class="mr-3 w-2/6">
          <pw-input type="text" label="Name" placeholder="Enter participant name" class="mr-2" v-model={this.filters.name} />
        </div>
        <div class="mr-3 w-2/6">
          <pw-input type="date" label="Start Date" placeholder="Enter start date" class="mr-2" v-model={this.filters.startDate} />
        </div>
        <div class="mr-3 w-2/6">
          <pw-input type="date" label="End Date" placeholder="Enter end date" class="mr-2" v-model={this.filters.endDate} />
        </div>
        <w-pb type="button" class="square-btn user_search-btn mr-2" onClick={() => this.onFilter(this.filters)} icon="Search" />
        <w-pb
          type="button"
          tooltip-text="Clear all filter"
          class="square-btn user_clear-btn relative tooltip mr-2"
          onClick={() => {
            this.filters = { ...initFilter };
            this.onFilter({ ...initFilter });
          }}
          icon="ClearFilter"
        />
        <w-pb
          tooltip-text="Export csv data"
          type="button"
          class="square-btn user_search-btn relative tooltip"
          icon="Download"
          onClick={() => this.onDownload()}
        />
      </section>
    );
  },
});
